import { Global, css } from '@emotion/react';
import * as React from 'react';
import Head from 'next/head';
import styled from '@emotion/styled';
import { useObservability } from '../../../../helpers/observability';

import { Box, Text, Button } from '@opendoor/bricks/core';

import odometerCSS from '../../../shared/odometerCSS';
import useOdometer from '../../../shared/useOdometer';

declare global {
  interface Window {
    odometerOptions: any;
    Odometer: any;
  }
}

export const CtaButton = styled(Button)`
  border-radius: 10px;
  padding: 0.5em 1em;
  height: 50px;

  @media (min-width: 767px) {
    font-size: 14px;
  }
`;

const assetPrefix = 'https://cdn-cosmos.opendoor.com/js';

const SocialProofOdometer = () => {
  const odometerStart = 388456;
  const [inViewRef, inView, odometerRef] = useOdometer(odometerStart);
  const { trackEvent } = useObservability();

  const onClickFn = () => {
    // TODO: remove categoryOverride
    // observability is initialized with 'cosmos' as the category for all events
    trackEvent('cta-click', 'social-proof-odometer-get-started', undefined, {
      categoryOverride: 'homepage',
    });
    window.location.assign('/address-entry');
  };

  return (
    <React.Fragment>
      <Head>
        <script async src={`${assetPrefix}/odometer.min.js`} />
      </Head>
      <Global styles={css(odometerCSS)} />
      <Box
        as="section"
        id="social-proof-odometer-container"
        ref={inViewRef}
        mt={[11]}
        mb={[5]}
        mx={[5]}
        pt={[11, null, '54px', '108px']}
        pb={['80px', null, '64px', '90px']}
        backgroundColor="blue30"
        borderRadius="roundedSquare"
      >
        <Box width={['85%', null, null, 'initial']} mx="auto" lineHeight="100" textAlign="center">
          {inView && (
            <Box
              // @ts-expect-error TODO - @growth to resolve
              fontSize={['s4', null, '64px', '74px']}
              fontWeight="bolder"
              //@ts-expect-error - var doesnt exist to support values
              color="#1C85E8"
              //@ts-expect-error - var doesnt exist to support values
              lineHeight={['mobileHeader', 'mobileHeader', '70px', '80px']}
            >
              <Box ref={odometerRef} id="odometer" display="inline-block">
                {odometerStart}
              </Box>
              +
            </Box>
          )}
          <Text
            //@ts-expect-error - var doesnt exist to support values
            fontSize={['s4', 's4', '64px', '74px']}
            lineHeight={['s3', 's3', 's3', 's4']}
            fontWeight="bolder"
            //@ts-expect-error - var doesnt exist to support values
            color="#1C85E8"
            mb="3"
            mt={[0, 0, 0, '3px']}
          >
            offers requested
          </Text>
          <Text
            fontSize={['s1']}
            lineHeight={['s0']}
            mt={[7, null, '50px', '30px']}
            color="neutrals100"
          >
            Every 60 seconds, a homeowner requests an offer from Opendoor
          </Text>
          <Box width={['174px']} mx="auto" mt={['35px', null, '20px', '32px']}>
            <CtaButton
              aria-label=""
              onClick={onClickFn}
              analyticsName="cosmos-homepage-socialProofOdometer-get-free-offer"
            >
              Get my free offer
            </CtaButton>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SocialProofOdometer;
