import { FC } from 'react';
import { BoxProps, Flex } from '@opendoor/bricks/core';

type ContainerProps = { variant?: 'secondary'; maxWidth?: string };

/* storybook-check-ignore */
export const Container: FC<ContainerProps & BoxProps> = ({
  variant,
  maxWidth,
  children,
  ...props
}) => {
  const color = variant == 'secondary' ? 'blue30' : 'neutrals0';
  const maxW = maxWidth || '960px';

  return (
    <Flex
      borderRadius="roundedSquare"
      justifyContent="center"
      mx={variant === 'secondary' ? 5 : 0}
      px={5}
      backgroundColor={color}
      {...props}
    >
      <Flex borderRadius="roundedSquare" width="100%" maxWidth={maxW} flexDir="column">
        {children}
      </Flex>
    </Flex>
  );
};
