import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Box, Heading, Image, Button } from '@opendoor/bricks/core';
// import imgixUrl from '../../helpers/imgixUrl';
import * as analytics from '@opendoor/shared-fe/build/analytics';
import LazyLoad from 'react-lazyload';

const Section = styled.section`
  margin-bottom: 83px;

  @media (min-width: 776px) {
    margin: 0 16px;
    border-radius: 8px;
  }
`;

const Container = styled.div`
  border-radius: 8px;
  background-color: #eef4fa;
  border-bottom-left-radius: 60px;
  height: 350px;
  display: flex;
  margin: 0 auto;

  @media (min-width: 767px) {
    padding: 25px 0 57px;
    margin: 0 auto;
    max-width: 692px;
    height: 385px;
  }

  @media (min-width: 864px) {
    justify-content: initial;
    padding: 52px 0 57px;
    height: 450px;
    max-width: 930px;
    margin: 0 auto;
  }

  @media (min-width: 1193px) {
    justify-content: initial;
    border-bottom-left-radius: 80px;
    padding: 79px 0 98px;
    height: 460px;
    max-width: 930px;
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    height: 683px;
    margin: 0 auto;
    width: 340px;
  }
`;

const ContentContainer = styled.div`
  padding: 48px 0 0 0;
  max-width: 450px;
  display: inline-block;
  margin-top: 52px;

  @media (min-width: 768px) {
    padding: 0;
    width: 60%;
    margin-left: 80px;
    margin-top: 45px;
  }
  @media (max-width: 767px) {
    padding: 0px 0 0;
    margin-left: 0;
    text-align: center;
  }
  @media (max-height: 568px) {
    margin: 10px 5% 40px 5%;
  }
`;

const SubHeadline = styled.p`
  font-size: 16px;
  line-height: 26px;
  margin: 0 auto;
  margin-top: 32px;
  max-width: 244px;
  text-align: center;
  @media (max-width: 767px) {
    margin-top: 16px;
    padding: 0;
    text-align: intial;
    max-width: 244px;
  }

  @media (min-width: 768px) {
    color: #222222;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    max-width: 270px;
    margin-left: 0;
  }

  @media (min-width: 992px) {
    margin-top: 32px;
    text-align: left;
    max-width: 328px;
    margin-left: 0;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
  text-align: center;

  button {
    height: 50px;
    margin: 0 auto;
    width: 177px;
    background-color: #0d76d6;

    @media (min-width: 768px) {
      margin: initial;
    }
  }

  @media (min-width: 768px) {
    margin-top: 30px;
  }

  @media (min-width: 1200px) {
    margin-top: 25px;
  }
`;

const imageStyles = css`
  position: relative;
  border-radius: 30px;

  @media (min-width: 768px) {
    width: 220px;
    height: 445px;
    right: 16px;
    top: -8px;
  }

  @media (min-width: 992px) {
    height: 545px;
    width: 268px;
    right: -45px;
    top: 105px;
  }

  @media (min-width: 1200px) {
    height: 545px;
    width: 268px;
    right: 0px;
    top: 145px;
  }
`;

const BuyerPanel = () => (
  <Section id="buyer-panel-container" className="buyer-container">
    <Container>
      <ContentContainer>
        <Heading
          size="UNSAFE_h1"
          as="h1"
          textAlign={['center', null, 'left', 'left']}
          fontSize={['s3', 's3', '28px', '32px']}
          //@ts-expect-error - var doesnt exist to support values
          lineHeight={['s2', 's2', 's3', '38px']}
          fontWeight="semibold"
        >
          Buying a home?
          <br />
          We make that easy.
        </Heading>
        <SubHeadline className="sub-head">
          We'll help you buy a home on your terms and save thousands in the process.
        </SubHeadline>
        <ButtonContainer>
          <Box
            mx={[null, null, null, 'initial']}
            width={['100%', null, null, '40%']}
            textAlign={['center', null, 'left', 'initial']}
          >
            <Button
              aria-label=""
              /** @ts-expect-error TODO @growth Remove border radius override */
              borderRadius="roundedSquare"
              size="md"
              marginTop="10px"
              onClick={() => {
                analytics.trackEvent('homepage', 'cta-click', 'buying-info', {});
                window.location.href = 'https://www.opendoor.com/w/homes';
              }}
            >
              Browse homes
            </Button>
          </Box>
        </ButtonContainer>
      </ContentContainer>
      <LazyLoad>
        <Box
          width={['250px', null, '384px', '300px']}
          mt={['47px']}
          textAlign={['center']}
          alignSelf={['center', null, null, 'flex-end']}
        >
          <Image
            css={imageStyles}
            src="https://sell.opendoor.com/images/homepage-v4/phone-image.jpg"
            alt="A phone screen with the Opendoor app open and showing Opendoor homes with options to schedule a tour"
            height="403px"
            width="200px"
          />
        </Box>
      </LazyLoad>
    </Container>
  </Section>
);

export default BuyerPanel;
