import styled from '@emotion/styled';
import { useInView } from 'react-intersection-observer';

import { Box } from '@opendoor/bricks/core';

const Container = styled.div`
  display: none;
  @media (max-width: 767px) {
    border-top: 1px solid #d9e0e6;
    border-bottom: 1px solid #d9e0e6;
    padding: 23px 0 32px;
    text-align: center;
    display: block;
    margin: 0 16px;
  }
`;

const DownloadButton = styled.a`
  @media (max-width: 767px) {
    display: inline-block;
    margin: 0 5px;
  }
`;

const AppBanner = () => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  return (
    <Box ref={ref}>
      {inView && (
        <Container>
          <h6
            style={{ color: '#222222', fontSize: '14px', lineHeight: '16px', marginBottom: '18px' }}
          >
            GET THE APP
          </h6>
          <DownloadButton href="https://play.google.com/store/apps/details?id=com.opendoor.buyerapp&hl=en">
            <img
              src="https://imgdrop.imgix.net/3a52f003f27742f3b4384a3168352335.png"
              alt="Play store button"
              height={42}
            />
          </DownloadButton>
          <DownloadButton href="https://itunes.apple.com/us/app/opendoor-homes-all-day-open/id1143708068?mt=8">
            <img
              src="https://imgdrop.imgix.net/03f8e282af254a80ac28358e640319ab.svg"
              alt="App store button"
              height={42}
            />
          </DownloadButton>
        </Container>
      )}
    </Box>
  );
};

AppBanner.displayName = 'AppBanner';

export default AppBanner;
