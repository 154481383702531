import { Value } from '@opendoor/bricks/core/Select/select.types';
import DropdownSingleSelect from '@opendoor/bricks/core/Select/DropdownSingleSelect';

import {
  Text,
  Box,
  BoxProps,
  Flex,
  H4,
  Link,
  // TODO: Label should not be used directly and should stay as an internal utility for bricks form components. Please update the usage of Label.
  // eslint-disable-next-line no-restricted-imports
  Label,
} from '@opendoor/bricks/core';
import { FC } from 'react';
import { Container } from './Container';
import { EmailInput } from './EmailInput';
import typography from '../../components/landing-pages/typography';

const ContactCard: FC<BoxProps> = ({ children, ...props }) => (
  <Box borderRadius="roundedSquare" backgroundColor="blue30" minWidth={300} p={5} {...props}>
    {children}
  </Box>
);

interface IProps {
  markets?: [];
  campaignName: string;
}

const Contact = (props: IProps) => {
  const onMarketSelect = (value: Value | undefined) => {
    if (!value) return;
    const market = value.valueOf() as string;
    window.location.href = `https://www.opendoor.com/w/sell/${market}`;
  };
  return (
    <Container mt={11}>
      <Flex justifyContent="space-between" flexDir={['column', null, 'row']}>
        <Box
          borderRadius="roundedSquare"
          width="100%"
          mr={[0, null, 7]}
          py={8}
          px={8}
          backgroundColor="brand50"
          mb={[6, null, 0]}
        >
          <Flex flexDir="column" height="100%" justifyContent="space-between">
            <Flex flexDir="column">
              <H4 {...typography.h4} maxWidth="320px" color="neutrals0" mb={7}>
                Get expert tips in your inbox
              </H4>
              <Text fontSize="s1" lineHeight="s0" mb={7} maxWidth="300px" color="neutrals0">
                Discover ways to increase your home's value and stay on top of local trends.
              </Text>
            </Flex>
            <EmailInput campaignName={props.campaignName} />
          </Flex>
        </Box>
        <Flex justifyContent="space-between" flexDir="column">
          <ContactCard mb={6}>
            <Text fontSize="s0" lineHeight="s00" mb={4} fontWeight="semibold">
              Call us
            </Text>
            <Flex alignItems="flex-end" justifyContent="space-between">
              <Link
                mr={4}
                fontWeight="semibold"
                href="tel:888-352-7075"
                aria-label=""
                analyticsName="cosmos-contact-opendoor-phone"
              >
                888-352-7075
              </Link>
              <Text fontSize="s00" lineHeight="s000" mb={2}>
                6:00 AM to 7:00 PM PT
              </Text>
            </Flex>
          </ContactCard>
          <ContactCard mb={6}>
            <Text fontSize="s0" lineHeight="s00" mb={4} fontWeight="semibold">
              Email us
            </Text>
            <Link
              fontWeight="semibold"
              href="mailto:support@opendoor.com"
              aria-label=""
              analyticsName="cosmos-contact-opendoor-email"
            >
              support@opendoor.com
            </Link>
          </ContactCard>
          <ContactCard>
            <Flex mb="4">
              <Label fontWeight="semibold" id="location-selector" fontSize="s0">
                Location
              </Label>
            </Flex>
            <DropdownSingleSelect
              aria-labelledby="location-selector"
              aria-label="Select a location to explore"
              placeholder="Explore all our locations"
              onChange={onMarketSelect}
              options={props.markets}
            />
          </ContactCard>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Contact;
