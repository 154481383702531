import styled from '@emotion/styled';
import { useInView } from 'react-intersection-observer';

import { Box } from '@opendoor/bricks/core';
import { colors } from '@opendoor/bricks/theme/eero';

const Container = styled.div`
  display: none;

  @media (max-width: 767px) {
    padding: 30px;
    background: #f5f8fb;
    text-align: center;
    display: block;
  }
`;

const DownloadButton = styled.a`
  @media (max-width: 767px) {
    display: inline-block;
    margin: 0 5px;
  }
`;

const H2 = styled.h2`
  @media (max-width: 767px) {
    font-size: 22px;
    margin-bottom: 20px;
  }
`;

const AppBanner = () => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  return (
    <Box ref={ref}>
      {inView && (
        <Container>
          <h6 style={{ color: colors.brand50, fontSize: '12px' }}>GET THE APP</h6>
          <H2>Browse homes and unlock them for your own personal home tour.</H2>
          <DownloadButton href="https://play.google.com/store/apps/details?id=com.opendoor.buyerapp&hl=en">
            <img
              src="https://imgdrop.imgix.net/3a52f003f27742f3b4384a3168352335.png"
              alt="Play store button"
              height={40}
            />
          </DownloadButton>
          <DownloadButton href="https://itunes.apple.com/us/app/opendoor-homes-all-day-open/id1143708068?mt=8">
            <img
              src="https://imgdrop.imgix.net/03f8e282af254a80ac28358e640319ab.svg"
              alt="App store button"
              height={40}
            />
          </DownloadButton>
        </Container>
      )}
    </Box>
  );
};

export default AppBanner;
