import { Box, Flex, Text, Image, Heading, H3 } from '@opendoor/bricks/core';
import { shadows } from '@opendoor/bricks/theme/eero';
import imgixUrl from '../../helpers/imgixUrl';

const LineItem = (props: { header: string; text: string; reduceBottomMargin?: boolean }) => (
  <Flex
    height={['120px', null, '130px', '130px']}
    mb={props.reduceBottomMargin ? [-6, null, 'initial', 'initial'] : undefined}
  >
    <Box>
      <Image
        width="14px"
        src={imgixUrl('bf56f1ba64e54792bdc4af8385ae7c5b.png')}
        alt="Checkmark icon"
      />
    </Box>
    <Box pl="4" width={0.95}>
      <Text fontSize="s0" lineHeight="s00" fontWeight="semibold">
        {props.header}
      </Text>
      <Text mt={4} fontSize={['s00', null, 's0']}>
        {props.text}
      </Text>
    </Box>
  </Flex>
);

const SellingOptions = () => (
  <Box
    id="selling-options-container"
    as="section"
    mt={[9, null, 11, 11]}
    mb={12}
    textAlign="center"
  >
    <Heading
      size="UNSAFE_h4"
      as="h4"
      mx="auto"
      fontWeight="bold"
      lineHeight="heading"
      fontSize={['s3', null, 's3', 's5']}
      my={[null, null, 8, 8]}
      width={[0.7, null, 'initial']}
    >
      You’re in control of your home sale
    </Heading>
    <Flex
      margin="auto"
      maxWidth={[null, null, null, '960px']}
      flexDirection={['column', null, 'initial', 'initial']}
      justifyContent="space-between"
    >
      <Box
        mb={[7, null, '-24px', '-24px']}
        px={[5, null, 9]}
        pt={[8, null, 9]}
        pb={[6, null, 7]}
        mx="auto"
        width={['90%', null, '90%', '460px']}
        borderTop="10px solid"
        borderTopColor="brand50"
        borderRadius="roundedSquare"
        backgroundColor="neutrals0"
        textAlign="left"
        css={{ boxShadow: shadows.large }}
      >
        <H3 my={0}>Sell to Opendoor</H3>
        <Text
          py={[4, null, 7, 7]}
          fontSize={['s00', null, 's0']}
          borderBottom="1px solid"
          borderBottomColor="neutrals40"
        >
          We'll make you a competitive cash offer so you get a stress-free sale without listing.
        </Text>
        <Box pt={[7, null, 4, 4]}>
          <LineItem
            header="Get your offer and get paid"
            text="Sell directly to us and get paid in a matter of days, so you’ll have the cash you need to buy your next home."
          />
          <LineItem
            header="Skip showings and repairs"
            text="Do a video walkthrough and skip the showings. If repairs are needed, you can let us handle the work."
          />
          <LineItem
            header="Move on your schedule"
            text="We believe in an easier home sale. Choose your close date to avoid double-moves and double&#8209;mortgages."
          />
        </Box>
      </Box>
    </Flex>
  </Box>
);

export default SellingOptions;
