import type { TypographyProps } from '@opendoor/bricks/system/theme/typography.types';

export type ResponsiveParams = Omit<TypographyProps, 'fontFamily'>;

const typography: {
  [key: string]: ResponsiveParams;
} = {
  h4: {
    fontSize: ['s3', null, 's5', 's5'],
    lineHeight: ['s3', null, 's5', 's5'],
    fontWeight: 'bolder',
  },
};

export default typography;
