import { Box, Heading } from '@opendoor/bricks/core';
import Accordion from '@opendoor/bricks/deprecated/UNSAFE_Accordion';

import styled from '@emotion/styled';

const faqItems = [
  {
    id: 'faq-process',
    title: 'What does this process look like?',
    body: (
      <p>
        First, you’ll tell us your home address and answer some basic questions about your home and
        selling goals. Then, we’ll provide an estimated listing range based on comparable home sales
        and a comparative market analysis, along with an all-cash offer for you to review.
      </p>
    ),
  },

  {
    id: 'faq-have-agent',
    title: 'Will I have an agent?',
    body: (
      <p>
        Yes! You’ll have a local agent who knows your neighborhood. You can rely on them and they'll
        be there for you through every step.
      </p>
    ),
  },

  {
    id: 'faq-listed',
    title: 'Will my home be listed on the MLS?',
    body: (
      <p>
        Yes, Opendoor will list your home on the MLS. Our team will help you photograph and get your
        home market-ready.
      </p>
    ),
  },

  {
    id: 'faq-price',
    title: 'Can I set the listing price?',
    body: (
      <p>
        {' '}
        You’ll work with your agent to decide what listing price makes the most sense based on your
        selling goals.
      </p>
    ),
  },

  {
    id: 'faq-catche',
    title: 'What’s the catch?',
    body: (
      <p>
        {' '}
        When you list with Opendoor, we make it our goal to get you the most money possible for your
        home. When you get more money for your home, so do we, because we make money on commission.
      </p>
    ),
  },

  {
    id: 'faq-start',
    title: 'When do the 60 days start?',
    body: (
      <p>
        {' '}
        One of our recommended agents will review a listing presentation with you. During that
        conversation, they’ll also share your cash offer details and present a customized market
        analysis. Once you’ve reviewed everything with the agent, you'll have 60 days to accept
        Opendoor's cash offer.
      </p>
    ),
  },
];

const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1130px;
  position: relative;
  ::after {
    content: '';
    display: block;
    clear: both;
  }

  @media (max-width: 1199px) {
    max-width: 720px;
  }

  @media (max-width: 767px) {
    max-width: 90%;
  }
`;

/* storybook-check-ignore */
const FAQs = () => {
  return (
    <Box
      as="section"
      analyticsName="cosmos-lwc-section-faqs"
      pt={[10, null, 11]}
      id="faq-container"
    >
      <Container>
        <Heading
          size="UNSAFE_h1"
          as="h1"
          textAlign={['center', null, 'initial']}
          mb={[7, null, null, 8]}
          fontSize={['s3', null, 's5']}
          fontWeight="bolder"
        >
          Frequently asked questions
        </Heading>
        <Box mb={[8, null, null, 9]}>
          <Accordion arrowPosition="right" items={faqItems} shouldAnimate={true} />
        </Box>
      </Container>
    </Box>
  );
};

export default FAQs;
