import { useInView } from 'react-intersection-observer';
import { Box, Flex, Heading, Text } from '@opendoor/bricks/core';
import { Avatar } from '@opendoor/bricks/complex';
import { AutoScrollCarousel } from '@opendoor/cinderblocks/sell/AutoScrollCarousel/AutoScrollCarousel';
import { TESTIMONIAL_MAX_CONTENT_WIDTH } from 'components/list-with-certainty/shared/constants';

type BlueTextProps = {
  text: string;
};
/** wrapper for the quote text that is highlighted in brand blue */
const BlueText: React.FC<BlueTextProps> = ({ text }) => {
  return (
    <Text display="inline" color="brand50" fontWeight="inherit">
      {text}
    </Text>
  );
};

/* storybook-check-ignore */
const Testimonial = () => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  return (
    <Box id="testimonials-container" ref={ref}>
      {inView && (
        <Flex
          as="section"
          analyticsName="cosmos-lwc-section-testimonials"
          alignItems="center"
          flexDirection="column"
          backgroundPosition={['center 79%', null, 'center center']}
          px="40px"
          backgroundColor="brand50"
        >
          <Heading
            as="h2"
            textAlign="center"
            maxW="1195px"
            fontSize={['s3', null, 's4', null, 's5']}
            lineHeight={['s3', null, 's4', null, 's5']}
            px={['7', null, '11', null, '9']}
            pt={[9, null, 11]}
            mb="7"
            color="neutrals30"
          >
            We’ll make it happen together
          </Heading>
          {/* Customer Quotes carousel */}
          <AutoScrollCarousel
            ms={5000}
            width={100}
            snapAdjustment={150}
            transition="ease 1s"
            indicatorType="pills"
            enableMobileTouch={false}
          >
            <QuoteWrapper>
              <QuoteOne />
            </QuoteWrapper>
            <QuoteWrapper>
              <QuoteTwo />
            </QuoteWrapper>
          </AutoScrollCarousel>
        </Flex>
      )}
    </Box>
  );
};
const QuoteWrapper: React.FC = ({ children }) => {
  return (
    <Flex
      as="section"
      analyticsName="cosmos-lwc-testimonial"
      justifyContent="center"
      h="95%"
      mb={5}
    >
      <Flex
        backgroundColor="neutrals0"
        borderRadius="roundedSmooth"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        maxWidth={TESTIMONIAL_MAX_CONTENT_WIDTH}
        mr={['10%', 8, 8]}
        p={['20px', null, null, null, '40px']}
      >
        {children}
      </Flex>
    </Flex>
  );
};

/** These quotes had specific sections of blue text that made it easier to split up into two different components  */
const QuoteOne = () => {
  return (
    <>
      {/* Customer testimonial */}
      <Text
        as="h3"
        textAlign="center"
        fontSize={['s2', null, 's4']}
        lineHeight={['s2', null, 's4', null, 's5']}
        mb="7"
        fontWeight="semibold"
      >
        “To [Opendoor] it’s not about the sale, it’s about trying to{' '}
        {<BlueText text="help families move on. " />}
        They treated me like I was their only client, and I had that{' '}
        {<BlueText text="one-on-one attention." />}”
      </Text>

      {/* Customer picture / Avatar w/ location */}
      <Flex flexDirection="inherit" alignItems="inherit">
        <Avatar
          size="lg"
          src="https://imgdrop.imgix.net/198b34c8f78d48c8a162ecb62da2e265.png"
          initials="CB"
          mb="3"
        />
        <Text fontWeight="semibold">Charlisa Boyd</Text>
        <Text>Sold to Opendoor in Raleigh, NC</Text>
      </Flex>
    </>
  );
};
const QuoteTwo = () => {
  return (
    <>
      {/* Customer testimonial */}
      <Text
        as="h3"
        textAlign="center"
        maxW={['285px', null, '1053px']}
        fontSize={['s2', null, 's4']}
        lineHeight={['s2', null, 's4', null, 's5']}
        mb="7"
        fontWeight="semibold"
      >
        “I was dealing with {<BlueText text="a real person " />}
        to make sure that all my questions were answered. The deciding factor for me was{' '}
        {<BlueText text="the customer service, the hands-on approach" />}. I was able to call
        someone when I got scared, when I wasn't sure...{' '}
        {<BlueText text="Opendoor listened to me and empathized" />}.”
      </Text>
      {/* Customer picture / Avatar w/ location */}
      <Flex flexDirection="inherit" alignItems="inherit">
        <DefaultAvatar />
        <Text fontWeight="semibold">Linda</Text>
        <Text>Sold to Opendoor in Atlanta, GA</Text>
      </Flex>
    </>
  );
};

const DefaultAvatar = () => (
  <svg width="75" height="74" viewBox="0 0 75 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="37.5" cy="37" r="37" fill="#E9E9E9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.5719 50.9932C55.9378 47.3829 58 42.5217 58 37.1746C58 26.0325 49.0457 17 38 17C26.9543 17 18 26.0325 18 37.1746C18 42.5251 20.0648 47.3891 23.4345 51L23.6019 50.4935C24.7407 47.0657 27.9259 44.7494 31.5093 44.7494H44.5C48.0926 44.7494 51.2778 47.0564 52.4074 50.4935L52.5719 50.9932ZM47 32.7941C47 37.8004 42.9722 41.8633 38.0093 41.8633C33.0463 41.8633 29.0185 37.8097 29.0185 32.7941C29.0185 27.7878 33.0463 23.7249 38.0093 23.7249C42.9722 23.7249 47 27.7878 47 32.7941Z"
      fill="#949494"
    />
  </svg>
);

export default Testimonial;
