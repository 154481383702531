import { Box, Heading, Text } from '@opendoor/bricks/core';
import Accordion from '@opendoor/bricks/deprecated/UNSAFE_Accordion';
import { useObservability } from '../../../../helpers/observability';
import styled from '@emotion/styled';

const AccordionStyles = styled.div`
  div[data-accordion-component='AccordionItem'] {
    font-weight: 400;
    :first-child {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      border-bottom: 1px solid #d9e0e6;
      border-top: 1px solid #d9e0e6;
    }

    :last-child {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      border: 1px solid #d9e0e6;
    }
    border-right: 1px solid #d9e0e6;
    border-left: 1px solid #d9e0e6;

    div svg {
      stroke: #1c85e8;
      width: 7px;
      stroke-width: 2px;
    }

    [class*='ItemTitleContent'] {
      font-weight: 600;
      line-height: 24px;
    }
  }
`;

const ContentContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  position: relative;
  ::after {
    content: '';
    display: block;
    clear: both;
  }
`;

const ANIMATION_DELAY = 0.3;

const faqItems = [
  {
    id: 'any-cost-or-obligation',
    title: 'Is there any cost or obligation if I request an offer?',
    body: (
      <p>
        Receiving an offer on your home from Opendoor is free, and comes with absolutely no
        obligation to sell. To get started, enter your address at the top of this page, and we’ll
        walk you through some quick questions about your home’s condition, features, and upgrades.
      </p>
    ),
  },
  {
    id: 'whats-the-catch',
    title: "This seems too good to be true — what's the catch?",
    body: (
      <>
        <p>
          We hear this from people who are unfamiliar with our business model, and it’s a natural
          concern: “How can you give me a competitive offer on my home and still make enough money
          to operate? There must be a catch.”
        </p>
        <p>
          Unlike flippers or other buy-low, sell-high investors, our business model is fee-based. We
          use recent, comparable home sales to make a competitive offer on your home. Then, if you
          decide to sell to us, we take a service charge out of the sale proceeds similar to how an
          agent takes a commission in a traditional sale. Every month we buy hundreds of homes
          helping homeowners across the country get to their next chapter.
        </p>
      </>
    ),
  },
  {
    id: 'types-of-homes',
    title: 'What types of homes does Opendoor purchase?',
    body: (
      <p>
        Visit our{' '}
        <a href="https://www.opendoor.com/w/faq/what-types-of-homes-does-opendoor-purchase">
          FAQ page
        </a>{' '}
        to learn more about the types of homes Opendoor purchases.
      </p>
    ),
  },
];

const FAQs = () => {
  const { trackEvent } = useObservability();
  return (
    <Box
      as="section"
      pt={['30px', null, '100px', '72px']}
      mx="auto"
      maxWidth={['340px', null, '690px', '930px', null]}
      id="faq-container"
    >
      <ContentContainer>
        <Heading
          as="h4"
          color="neutrals100"
          textAlign={['initial', null, null, 'initial']}
          mb={[7, null, '44px', '32px']}
          fontSize={['s3', 's3', 's4', 's4']}
          fontWeight={['semibold']}
          lineHeight={['s3']}
        >
          Frequently asked questions
        </Heading>
        <Box mb={[7, null, null, 9]}>
          <AccordionStyles>
            <Accordion arrowPosition="right" items={faqItems} shouldAnimate noBorder />
          </AccordionStyles>
        </Box>
        <Text fontWeight="semibold" textAlign={['left']}>
          <a
            // TODO: remove categoryOverride
            // observability is initialized with 'cosmos' as the category for all events
            onClick={() =>
              trackEvent('cta-click', 'faq-see-all', undefined, { categoryOverride: 'homepage' })
            }
            href="https://www.opendoor.com/w/faq/"
            target="_blank"
            data-aos="fade-up"
            data-aos-delay={`${(faqItems.length + 1) * ANIMATION_DELAY}s`}
            rel="noreferrer"
          >
            See all FAQs
          </a>
        </Text>
      </ContentContainer>
    </Box>
  );
};

export default FAQs;
