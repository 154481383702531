import * as React from 'react';
import { useInView } from 'react-intersection-observer';

const useOdometer = (
  odometerStart: number,
): [(node?: Element | null) => void, boolean, React.RefObject<HTMLDivElement>] => {
  const [inViewRef, inView, entry] = useInView({ triggerOnce: true, threshold: 0.4 });
  const odometerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (!inView || !entry) {
      return;
    }
    window.odometerOptions = {
      auto: false,
      format: 'ddd,ddd',
      theme: 'minimal',
      animation: 'count',
    };
    const GlobalOdometer = window.Odometer;
    if (!GlobalOdometer || !odometerRef.current) {
      return;
    }
    const od = new GlobalOdometer({
      el: odometerRef.current,
      theme: 'minimal',
      value: odometerStart,
      duration: 1000,
    });
    od.update(1800000);
  }, [inView, entry, odometerRef]);

  return [inViewRef, inView, odometerRef];
};

export default useOdometer;
