import React from 'react';
import {
  AccordionListItem,
  AccordionListItemProps,
  Box,
  Heading,
  List,
  Text,
} from '@opendoor/bricks/core';
import { useObservability } from '../../helpers/observability';
import styled from '@emotion/styled';

const ANIMATION_DELAY = 0.3;

const FAQ_ITEMS: Array<AccordionListItemProps> = [
  {
    title: 'Is there any cost or obligation if I request an offer?',
    panelId: 'any-cost-or-obligation',
    children: (
      <p>
        Receiving an offer on your home from Opendoor is free, and comes with absolutely no
        obligation to sell. To get started, enter your address at the top of this page, and we’ll
        walk you through some quick questions about your home’s condition, features, and upgrades.
      </p>
    ),
  },
  {
    title: "This seems too good to be true — what's the catch?",
    panelId: 'whats-the-catch',
    children: (
      <>
        <p>
          We hear this from people who are unfamiliar with our business model, and it’s a natural
          concern: “How can you give me a competitive offer on my home and still make enough money
          to operate? There must be a catch.”
        </p>
        <p>
          Unlike flippers or other buy-low, sell-high investors, our business model is fee-based. We
          use recent, comparable home sales to make a competitive offer on your home. Then, if you
          decide to sell to us, we take a service charge out of the sale proceeds similar to how an
          agent takes a commission in a traditional sale. Every month we buy hundreds of homes
          helping homeowners across the country get to their next chapter.
        </p>
      </>
    ),
  },
  {
    title: 'What types of homes does Opendoor purchase?',
    panelId: 'types-of-homes',
    children: (
      <p>
        Visit our{' '}
        <a href="https://www.opendoor.com/w/faq/what-types-of-homes-does-opendoor-purchase">
          FAQ page
        </a>{' '}
        to learn more about the types of homes Opendoor purchases.
      </p>
    ),
  },
];

const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 800px;
  position: relative;
  ::after {
    content: '';
    display: block;
    clear: both;
  }

  @media (max-width: 1199px) {
    max-width: 720px;
  }

  @media (max-width: 767px) {
    max-width: 90%;
  }
`;

const FAQs = () => {
  const { trackEvent } = useObservability();
  return (
    <Box as="section" pt={[10, null, 11, 11]} id="faq-container">
      <Container>
        <Heading
          size="UNSAFE_h1"
          as="h1"
          textAlign={['center', null, 'initial', 'initial']}
          mb={[7, null, null, 8]}
          fontSize={['s3', null, 's5', 's5']}
          fontWeight="bolder"
        >
          Frequently Asked Questions
        </Heading>
        <Box mb={[8, null, null, 9]}>
          {/* <Accordion arrowPosition="right" items={faqItems} shouldAnimate={true} /> */}
          <List variant="accordion" bgColor="none" sx={{ li: { px: 4 } }}>
            {FAQ_ITEMS.map(({ children, title, ...faq }) => (
              <AccordionListItem
                {...faq}
                key={faq.panelId}
                analyticsName={`homepage-faq-${faq.panelId}`}
                wrapText={false}
                whiteSpace="normal"
                variant="secondary"
                title={
                  <Text fontWeight="bold" lineHeight="s1">
                    {title}
                  </Text>
                }
              >
                {React.Children.map(children, (child) => (
                  <Text lineHeight="s1">{child}</Text>
                ))}
              </AccordionListItem>
            ))}
          </List>
        </Box>
        <Text fontWeight="semibold" textAlign={['center', null, 'initial', 'initial']}>
          <a
            // TODO: remove categoryOverride
            // observability is initialized with 'cosmos' as the category for all events
            onClick={() =>
              trackEvent('cta-click', 'faq-see-all', undefined, { categoryOverride: 'homepage' })
            }
            href="https://www.opendoor.com/w/faq/"
            target="_blank"
            data-aos="fade-up"
            data-aos-delay={`${(FAQ_ITEMS.length + 1) * ANIMATION_DELAY}s`}
            rel="noreferrer"
          >
            See all FAQ
          </a>
        </Text>
      </Container>
    </Box>
  );
};

export default FAQs;
