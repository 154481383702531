import { useInView } from 'react-intersection-observer';
import Slider from 'react-slick';

import { Box, Heading, Text } from '@opendoor/bricks/core';
import imgixUrl from '../../helpers/imgixUrl';
import GlobalSlickStyles from '../shared/GlobalSlickStyles';
import { useObservability } from '../../helpers/observability';
interface QuoteItemProps {
  href: string;
  quoteAuthor: string;
  locationText: string;
  children: any;
}

const QuoteItem = (props: QuoteItemProps) => {
  const { trackEvent } = useObservability();
  return (
    <Box mx="auto" width={['90%', null, 'initial', 'initial']}>
      <Text mb={['initial', null, 7, 7]} textAlign="center" lineHeight="150" fontSize="s1">
        {props.children}
      </Text>
      <Box mt={[7, null, 'initial', 'initial']}>
        <a
          // TODO: remove categoryOverride
          // observability is initialized with 'cosmos' as the category for all events
          onClick={() =>
            trackEvent('cta-click', 'customer-story-link', undefined, {
              categoryOverride: 'homepage',
            })
          }
          href={props.href}
          target="_blank"
          rel="noreferrer"
        >
          {props.quoteAuthor}
        </a>
        <br />
        {props.locationText}
      </Box>
    </Box>
  );
};

const Testimonials = () => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  return (
    <Box id="testimonials-container" ref={ref}>
      <GlobalSlickStyles />
      {inView && (
        <Box
          as="section"
          backgroundPosition="center"
          backgroundSize="cover"
          height={['480px', null, '770px', '770px']}
          backgroundImage={[
            `url(${imgixUrl('7b1d6a3b57314ca5b150a1ab0c950303.jpg')})`,
            null,
            `url(${imgixUrl('682db723a61346818552c415f0773113.jpg')})`,
          ]}
        >
          <Heading
            size="UNSAFE_h4"
            as="h4"
            mt={0}
            textAlign={['center', null, 'center', 'center']}
            fontWeight={['semibold', null, 'bold', 'bold']}
            pt={[9, null, 11, 11]}
            lineHeight="heading"
            fontSize={['s2', null, 's5', 's5']}
          >
            9 out of 10 customers who sell
            <br />
            to Opendoor recommend us
          </Heading>
          <section aria-roledescription="carousel" aria-label="Highlighted Opendoor reviews">
            <Box
              width={['90%', null, '70%', '70%']}
              textAlign="center"
              mx="auto"
              mt={[7, null, 8, 8]}
            >
              <Slider dots={false} arrows={true} autoplay={false} accessibility={true}>
                <QuoteItem
                  href="https://www.opendoor.com/w/blog/adam_leon"
                  quoteAuthor="Adam Leon"
                  locationText="Sold to Opendoor in Phoenix, AZ"
                  aria-roledescription="slide"
                  aria-label="1 of 5"
                >
                  Opendoor’s offer came in right near our appraisal, but we never had to list the
                  house or do showings. For the kind of value Opendoor gives you, it’s just a
                  no-brainer.
                </QuoteItem>
                <QuoteItem
                  href="https://www.opendoor.com/w/stories/charlisa-boyd"
                  quoteAuthor="Charlisa Boyd"
                  locationText="Sold to Opendoor in Raleigh, NC"
                  aria-roledescription="slide"
                  aria-label="2 of 5"
                >
                  To them it’s not about the sale, it’s about trying to help families move on. They
                  treated me like I was their only client, and I had that one-on-one attention.
                </QuoteItem>
                <QuoteItem
                  href="https://www.opendoor.com/w/blog/bertotys"
                  quoteAuthor="George and Carol Bertoty"
                  locationText="Sold to Opendoor in Henderson, NV"
                  aria-roledescription="slide"
                  aria-label="3 of 5"
                >
                  We’re of the age where we didn’t want to do a lot of work on the house. We just
                  wanted out of there! Move on with life—and Opendoor helped us do that.
                </QuoteItem>
                <QuoteItem
                  href="https://www.opendoor.com/w/blog/ohlenbusch"
                  quoteAuthor="Kurt and Christi Ohlenbusch"
                  locationText="Sold to Opendoor in Dallas, TX"
                  aria-roledescription="slide"
                  aria-label="4 of 5"
                >
                  We didn’t want the extra stress of buyers backing out, financing delays, all that.
                  That’s when our agent suggested selling to Opendoor, and it was such a smooth
                  sale.
                </QuoteItem>
                <QuoteItem
                  href="https://www.opendoor.com/w/stories/anne-and-jim-nash"
                  quoteAuthor="Anne and Jim Nash"
                  locationText="Sold to Opendoor in Sacramento, CA"
                  aria-roledescription="slide"
                  aria-label="4 of 5"
                >
                  It was quick, it was painless, and the price offered was exceedingly fair. It
                  alleviated all of the hassles that we would have gone through — listing it,
                  staging it and on and on.
                </QuoteItem>
              </Slider>
            </Box>
          </section>
        </Box>
      )}
    </Box>
  );
};

export default Testimonials;
