import styled from '@emotion/styled';

import { colors } from '@opendoor/bricks/theme/eero';
import { Box, Heading, Image, Button } from '@opendoor/bricks/core';
import imgixUrl from '../../helpers/imgixUrl';
import { useObservability } from '../../helpers/observability';
import LazyLoad from 'react-lazyload';

const Section = styled.section`
  background-color: ${colors.blue30};
`;

const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 960px;
  height: 400px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    height: initial;
    max-width: 90%;
  }
`;

const ContentContainer = styled.div`
  padding: 48px 0 0 0;
  max-width: 450px;
  display: inline-block;

  @media (max-width: 991px) {
    padding: 32px 0;
    width: 50%;
  }

  @media (max-width: 767px) {
    padding: 40px 0;
    width: 100%;
  }

  @media (max-height: 568px) {
    margin: 10px 5% 40px 5%;
  }
`;

const SubHeadline = styled.p`
  font-size: 16px;
  line-height: 1.4;
  margin-top: 20px;

  @media (max-width: 767px) {
    margin-top: 16px;
    padding: 0 10px;
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  height: 50px;
  margin-top: 32px;
  margin-bottom: 12px;

  @media (max-width: 991px) {
    margin-top: 24px;
  }
`;

const BuyerPanel = () => {
  const { trackEvent } = useObservability();
  return (
    <Section id="buyer-panel-container" className="buyer-container">
      <Container>
        <ContentContainer>
          <Heading
            size="UNSAFE_h1"
            as="h1"
            textAlign={['center', null, 'initial', 'initial']}
            fontSize={['s3', null, 's5', 's5']}
            fontWeight="bolder"
          >
            Buying a home, too?
            <br />
            We make that easy.
          </Heading>
          <SubHeadline className="sub-head">
            Avoid double mortgages and moves when you sell and buy a home with Opendoor.
          </SubHeadline>
          <ButtonContainer>
            <Box mx={['auto', null, 'initial', 'initial']} width={['80%', null, '40%', '40%']}>
              <Button
                aria-label=""
                analyticsName="cosmos-homepage-buy-and-sell-FAQs-opendoor-complete"
                data-testid="learn-more-about-opendoor-complete"
                /** @ts-expect-error TODO @growth Remove border radius override */
                borderRadius="rounded"
                size="md"
                marginTop="10px"
                onClick={() => {
                  // TODO: remove categoryOverride
                  // observability is initialized with 'cosmos' as the category for all events
                  trackEvent('cta-click', 'buying-info', {}, { categoryOverride: 'homepage' });
                  window.location.href = '/buy-and-sell';
                }}
              >
                Learn more
              </Button>
            </Box>
          </ButtonContainer>
        </ContentContainer>
        <LazyLoad>
          <Box
            width={['250px', null, '300px', '300px']}
            alignSelf={['center', null, 'flex-end', 'flex-end']}
          >
            <Image
              src={imgixUrl('403e7a4013af4be9b5696828ec240f00.png')}
              alt="A phone screen with the Opendoor app open and showing Opendoor homes with options to schedule a tour"
            />
          </Box>
        </LazyLoad>
      </Container>
    </Section>
  );
};

export default BuyerPanel;
