import { useInView } from 'react-intersection-observer';
import Slider from 'react-slick';
import { Box, Text } from '@opendoor/bricks/core';
import GlobalSlickStyles from '../../../shared/GlobalSlickStyles';
import { css } from '@emotion/react';
import { useObservability } from '../../../../helpers/observability';

interface QuoteItemProps {
  href: string;
  quoteAuthor: string;
  locationText: string;
  children: string;
}

const testimonialStyle = css`
  .text-outer-box {
    overflow-x: hidden;
  }

  a {
    color: #202c4a !important; //override global link style
  }
`;

// Override slick styles
const arrowCSS = css`
  .slick-arrow {
    top: 120%;
    background-size: 15px 15px;
    border: 1;
    opacity: 1;

    @media (min-width: 767px) {
      top: 111%;
    }
    @media (min-width: 1200px) {
      top: 105%;
    }
  }

  .slick-arrow.slick-prev {
    background: url('https://sell.opendoor.com/images/homepage-v4/arrow-left.svg') no-repeat;
    background-position: center;
    left: 36%;

    @media (min-width: 767px) {
      left: 5%;
    }
    @media (min-width: 1200px) {
      left: 0;
    }
  }

  .slick-arrow.slick-prev:hover {
    background: url('https://sell.opendoor.com/images/homepage-v4/arrow-left.svg') no-repeat !important;
    background-position: center !important;
  }

  .slick-arrow.slick-next {
    right: 36%;
    background: url('https://sell.opendoor.com/images/homepage-v4/arrow-right.svg') no-repeat;
    background-position: center;

    @media (min-width: 767px) {
      right: 80%;
    }
    @media (min-width: 1200px) {
      right: 77%;
    }
  }

  .slick-arrow.slick-next:hover {
    background: url('https://sell.opendoor.com/images/homepage-v4/arrow-right.svg') no-repeat !important;
    background-position: center !important;
  }

  button {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 20%);
  }

  .button-link {
    padding: 0;
    background-color: transparent;
    border: none;
    width: auto;
    height: auto;
    box-shadow: unset;
    border-radius: unset;
    cursor: pointer;
  }

  .button-link:hover {
    background: unset !important;
  }

  svg {
    width: 26px;
    height: 18px;
  }
`;

const QuoteIcon = () => (
  <Box
    color="brand50"
    textAlign="center"
    m={['36px auto 0', null, '45px 0 0', '36px 0 0']}
    width="26px"
    height="18px"
  >
    <svg viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.26555 10C4.80861 10 6.13636 9.00383 6.13636 7.35632C6.13636 5.6705 5.20335 4.71264 3.91148 4.71264C3.44498 4.71264 3.01435 4.90422 2.83493 5.05747C2.76316 3.5249 3.80383 2.03065 6.13636 0.919539L5.88517 0C2.04545 0.881226 0 3.10345 0 6.32184C0 8.77395 1.61483 10 3.26555 10ZM10.7656 10C12.3086 10 13.6005 9.00383 13.6005 7.35632C13.6005 5.6705 12.6675 4.71264 11.3756 4.71264C10.9091 4.71264 10.4785 4.90422 10.299 5.05747C10.2273 3.5249 11.2679 2.03065 13.6364 0.919539L13.3493 0C9.50957 0.881226 7.46412 3.10345 7.46412 6.32184C7.46412 8.77395 9.07895 10 10.7656 10Z"
        fill="#1C85E8"
      />
    </svg>
  </Box>
);

const QuoteItem = (props: QuoteItemProps) => {
  const { trackEvent } = useObservability();
  return (
    <Box
      mx="auto"
      width={['90%', null, null, 'initial']}
      textAlign={['center', null, 'left', 'left']}
    >
      <Text
        mb={['initial', null, null, 8]}
        lineHeight={['s0', null, 's1', 's1']}
        fontSize={['s0', null, 's2', null]}
        // @ts-expect-error - deprecated color
        color="#202C4A"
      >
        {props.children}
      </Text>
      <Box mt={[7, null, null, 'initial']}>
        <a
          // TODO: remove categoryOverride
          // observability is initialized with 'cosmos' as the category for all events
          onClick={() =>
            trackEvent('cta-click', 'customer-story-link', undefined, {
              categoryOverride: 'homepage',
            })
          }
          href={props.href}
          target="_blank"
          rel="noreferrer"
        >
          <Text m="0" fontWeight="semibold">
            {props.quoteAuthor}
          </Text>
        </a>
        <Text // @ts-expect-error - deprecated color
          color="#202C4A"
          fontSize="s00"
        >
          {props.locationText}
        </Text>
      </Box>
    </Box>
  );
};

QuoteItem.displayName = 'QuoteItem';

const Testimonials = () => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  return (
    <Box
      id="testimonials-container"
      ref={ref}
      mx={[0, 0, '16px']}
      mt={[5]}
      mb={['512px', null, '452px', '118px']}
      css={testimonialStyle}
    >
      <Box
        as="section"
        backgroundPosition="bottom"
        backgroundSize="cover"
        borderRadius={['none', 'none', 'roundedSquare', 'roundedSquare']}
        height={['220px', null, '486px', '660px']}
        p={['1px']}
        backgroundImage={[
          `url('https://sell.opendoor.com/images/homepage-v4/posed-couple-full-m.png')`,
          null,
          `url('https://sell.opendoor.com/images/homepage-v4/posed-couple-full-t.png')`,
          `url('https://sell.opendoor.com/images/homepage-v4/posed-couple-full-d.png')`,
        ]}
      >
        <GlobalSlickStyles />
        <Box width={['100%', '100%', '690px', '930px']} mx={['auto']}>
          {inView && (
            <Box
              boxShadow="rgb(0 0 0 / 10%) 0px 3px 15px 2px"
              className="text-outer-box"
              backgroundColor="neutrals0"
              //@ts-expect-error - var doesnt exist to support values
              borderRadius={['semiRounded', 'semiRounded', 'semiRounded', '10px 10px 60px 10px']}
              height={['496px', null, '443px', '528px']}
              width={['340px', '340px', 'inherit', '448px']}
              m={['193px auto 0', null, '400px auto 0', '65px 0 0 0']}
              p={['38px 0 0 0', null, '47px 0 0 50px', '44px 0 0 46px']}
            >
              <Text
                // @ts-expect-error - deprecated color
                color="#202C4A"
                textAlign={['center', null, 'left', 'left']}
                fontWeight="semibold"
                lineHeight={['s2', null, 's4', 's4', null]}
                fontSize={['s3', null, 's4', 's4']}
                width={['283px', null, '467px', '376px']}
                mx={['auto', null, '0', '0']}
              >
                9 out of 10 customers who sell to Opendoor recommend us
              </Text>
              <QuoteIcon />
              <Box
                width={['90%', null, '85%', '90%']}
                ml={['auto', 'auto', '28px', 0]}
                mr={['auto', 'auto', null]}
                mt={[7, null, '-22px', 8]}
              >
                <Slider dots={false} arrows autoplay accessibility css={arrowCSS}>
                  <QuoteItem
                    href="https://www.opendoor.com/w/blog/adam_leon"
                    quoteAuthor="Adam Leon"
                    locationText="Sold to Opendoor in Phoenix, AZ"
                  >
                    Opendoor's offer came in right near our appraisal, but we never had to list the
                    house or do showings. For the kind of value Opendoor gives you, it's just a
                    no-brainer.
                  </QuoteItem>
                  <QuoteItem
                    href="https://www.opendoor.com/w/stories/charlisa-boyd"
                    quoteAuthor="Charlisa Boyd"
                    locationText="Sold to Opendoor in Raleigh, NC"
                  >
                    To them it's not about the sale, it's about trying to help families move on.
                    They treated me like I was their only client, and I had that one-on-one
                    attention.
                  </QuoteItem>
                  <QuoteItem
                    href="https://www.opendoor.com/w/blog/bertotys"
                    quoteAuthor="George and Carol Bertoty"
                    locationText="Sold to Opendoor in Henderson, NV"
                  >
                    We're of the age where we didn't want to do a lot of work on the house. We just
                    wanted out of there! Move on with life—and Opendoor helped us do that.
                  </QuoteItem>
                  <QuoteItem
                    href="https://www.opendoor.com/w/blog/ohlenbusch"
                    quoteAuthor="Kurt and Christi Ohlenbusch"
                    locationText="Sold to Opendoor in Dallas, TX"
                  >
                    We didn't want the extra stress of buyers backing out, financing delays, all
                    that. That's when our agent suggested selling to Opendoor, and it was such a
                    smooth sale.
                  </QuoteItem>
                  <QuoteItem
                    href="https://www.opendoor.com/w/stories/anne-and-jim-nash"
                    quoteAuthor="Anne and Jim Nash"
                    locationText="Sold to Opendoor in Sacramento, CA"
                  >
                    It was quick, it was painless, and the price offered was exceedingly fair. It
                    alleviated all of the hassles that we would have gone through — listing it,
                    staging it and on and on.
                  </QuoteItem>
                </Slider>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

Testimonials.displayName = 'Testimonials';

export default Testimonials;
