import { css, Global } from '@emotion/react';

const GlobalCss = css`
  /* Slider */
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: grabbing;
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  .slick-arrow {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    color: transparent;
    border: 0;
    top: 28%;
    background-color: transparent;
    position: absolute;
    opacity: 0.4;
    cursor: pointer;
  }
  .slick-arrow:hover {
    opacity: 0.8;
  }
  .slick-arrow.slick-next {
    background: url('https://imgdrop.imgix.net/6702d4b9f1ee4a6f97c31406e9a5894b.svg') center
      no-repeat;
    right: -8%;
  }
  .slick-arrow.slick-prev {
    background: url('https://imgdrop.imgix.net/086e83b6f4bd41c59d54fc7cc93c67a5.svg') center
      no-repeat;
    left: -8%;
  }
`;

// storybook-check-ignore
const GlobalSlickStyles = () => {
  return <Global styles={css(GlobalCss)} />;
};

export default GlobalSlickStyles;
