import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Box, Flex, Text, Link, LinkProps } from '@opendoor/bricks/core';
import { colors } from '@opendoor/bricks/theme/eero';
import RegularContainer from '../RegularContainer';
import { useObservability } from '../../helpers/observability';

const FooterCol = styled.div`
  width: 25%;

  @media (max-width: 767px) {
    float: left;
    width: 45%;
    margin-right: 5%;
    text-align: left;
  }
`;

const FooterColA: React.FC<{ label: string } & Pick<LinkProps, 'href' | 'style'>> = ({
  label,
  href,
  style,
  children,
}) => {
  const { trackEvent } = useObservability();
  return (
    <Link
      color="neutrals90"
      // TODO: add analyticsName and remove onClick
      analyticsName=""
      aria-label={null}
      href={href}
      style={style}
      onClick={() =>
        // TODO: remove categoryOverride
        // observability is initialized with 'cosmos' as the category for all events
        trackEvent('cta-click', label, undefined, {
          categoryOverride: 'homepage',
        })
      }
    >
      {children}
    </Link>
  );
};

const FooterColUL = styled.ul`
  padding: 0;
  margin: 10px 0 30px 0;
  list-style: none;
`;

const FooterColLI = styled.li`
  padding: 0;
  margin: 10px 0;
`;

const hiddenXSDown = css`
  @media (max-width: 767px) {
    display: none !important;
  }
`;

const leftSpaceXs = css`
  @media (max-width: 767px) {
    margin-left: 25px;
  }
`;

const H6 = styled.h6`
  text-transform: uppercase;
  margin: 20px 0;
  font-size: 14px;
  color: ${colors.neutrals100};
`;

const SocialIcons = styled.div`
  flex: 1;
  padding: 0 0 50px 0;
  text-align: center;

  @media (max-width: 767px) {
    margin: 15px 0;
    padding: 30px 0;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
  }
`;

const SocialIconA = styled.a`
  display: inline-block;
  margin: 0 10px;
`;

const FooterSection = styled.footer`
  padding: 60px 0;

  @media (max-width: 767px) {
    padding: 30px;
    padding-bottom: 120px;
  }
`;

const FairHousing = styled.img`
  height: 45px;
  @media (max-width: 767px) {
    margin-top: 20px;
    height: 35px;
    float: none;
  }
`;

const LegalP = styled.p`
  font-size: 12px;
  color: ${colors.neutrals90};
  margin: 12px 0;

  a {
    font-size: 12px;
    text-decoration: underline;
    margin: 12px 0;
  }
`;

const copyRow = css`
  color: ${colors.neutrals90};
  font-size: 14px;
  align-items: center;
  justify-content: center;
`;

interface IProps {
  hideLegalese?: boolean;
  addLegaleseText?: React.ReactNode;
  addLegaleseContent?: React.ReactNode;
}

/* storybook-check-ignore */
const Footer: React.FC<IProps> = (props: IProps) => {
  return (
    <FooterSection>
      <RegularContainer style={{ display: 'flex', justifyContent: 'space-between' }}>
        <FooterCol>
          <H6>Sell your home</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA label="sell-your-home-request-offer" href="https://www.opendoor.com/">
                Request an offer
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="sell-your-home-pricing" href="https://www.opendoor.com/w/pricing">
                Pricing
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="sell-your-home-reviews" href="https://www.opendoor.com/reviews">
                Reviews
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="sell-your-home-stories" href="https://www.opendoor.com/w/stories">
                Stories
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
          <H6>Buy a home</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA label="buy-a-home-buy" href="https://www.opendoor.com/w/homes">
                Buy
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="exclusive" href="https://opendoor.com/exclusive">
                Exclusives
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
          <H6>Partners</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA
                label="partners-vendors"
                href="https://www.opendoor.com/w/vendor-partnerships"
              >
                Vendors
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA
                label="partners-builder"
                href="https://www.opendoor.com/w/partner/builder"
              >
                Builder
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
        </FooterCol>
        <FooterCol css={hiddenXSDown}>
          <H6>Buy and sell</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA
                label="buy-and-sell-with-opendoor"
                href="https://www.opendoor.com/buy-and-sell"
              >
                Buy and sell with Opendoor
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="trade-in-builder" href="https://www.opendoor.com/w/builder">
                Builder trade-up
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
          <H6>Agents</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA
                label="agents-make-offer"
                href="https://www.opendoor.com/w/agents#alp-buy--form"
              >
                Make an offer
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="agents-request-offer" href="https://www.opendoor.com/w/agents">
                Request an offer
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
          <H6>Resources</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA label="resources-blog" href="https://www.opendoor.com/w/blog">
                Blog
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="resources-guides" href="https://www.opendoor.com/w/resources">
                Guides
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA
                label="article-briefs"
                href="https://www.opendoor.com/articles/topic/briefs"
              >
                Briefs
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="resources-faq" href="https://www.opendoor.com/faq">
                FAQ
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="resources-help-center" href="https://help.opendoor.com">
                Help Center
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
        </FooterCol>
        <FooterCol css={leftSpaceXs}>
          <H6>About</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA label="about-company" href="https://www.opendoor.com/w/about">
                Company
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="about-career" href="https://www.opendoor.com/jobs">
                Careers
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="about-press" href="https://www.opendoor.com/press">
                Press
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="about-contact" href="https://www.opendoor.com/w/contact-us">
                Contact
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="about-investors" href="https://investor.opendoor.com/">
                Investors
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
          <H6>Terms & privacy</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA label="terms-trust-safety" href="https://www.opendoor.com/w/safety">
                Trust & Safety
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="terms-tos" href="https://www.opendoor.com/w/terms">
                Terms of Service
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="terms-privacy" href="https://www.opendoor.com/w/privacy">
                Privacy Policy
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="terms-privacy" href="https://www.opendoor.com/w/privacy#ccpa">
                Notice at Collection
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="terms-do-not-sell" href="https://www.opendoor.com/privacyrequest">
                Do Not Sell My Info
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
        </FooterCol>
        <FooterCol css={hiddenXSDown}>
          <H6>Download App</H6>
          <FooterColA
            label="app-download-android"
            href="https://play.google.com/store/apps/details?id=com.opendoor.buyerapp&hl=en"
            style={{ marginBottom: '10px', display: 'block' }}
          >
            <img
              src="https://imgdrop.imgix.net/3a52f003f27742f3b4384a3168352335.png"
              alt="Play store button"
              width={140}
              loading="lazy"
            />
          </FooterColA>
          <FooterColA
            label="app-download-ios"
            href="https://itunes.apple.com/us/app/opendoor-homes-all-day-open/id1143708068?mt=8"
            style={{ marginBottom: '10px', display: 'block' }}
          >
            <img
              src="https://imgdrop.imgix.net/03f8e282af254a80ac28358e640319ab.svg"
              alt="App store button"
              width={140}
              loading="lazy"
            />
          </FooterColA>
        </FooterCol>
      </RegularContainer>

      <RegularContainer flex>
        <SocialIcons>
          <SocialIconA href="https://www.facebook.com/OpendoorHQ">
            <img
              src="https://imgdrop.imgix.net/1ac79b6da72048e5ac4e3770a67d9f51.svg"
              alt="Facebook"
              width="25px"
              height="25px"
              loading="lazy"
            />
          </SocialIconA>
          <SocialIconA href="https://twitter.com/Opendoor">
            <img
              src="https://imgdrop.imgix.net/f2641428da574ecf908264f2ab31a032.svg"
              alt="Twitter"
              width="25px"
              height="25px"
              loading="lazy"
            />
          </SocialIconA>
          <SocialIconA href="https://www.linkedin.com/company/opendoor-com/">
            <img
              src="https://imgdrop.imgix.net/eb24a868176f4dc182c1f3de199481e7.svg"
              alt="Linkedin"
              width="25px"
              height="25px"
              loading="lazy"
            />
          </SocialIconA>
        </SocialIcons>
        <a
          href="https://www.bbb.org/us/az/tempe/profile/real-estate-services/opendoor-1126-1000060421/#sealclick"
          target="_blank"
          rel="nofollow noopener"
        >
          <img
            src="https://seal-central-northern-western-arizona.bbb.org/seals/blue-seal-200-42-bbb-1000060421.png"
            alt="Opendoor BBB Business Review"
            style={{ display: 'block', margin: 'auto' }}
            loading="lazy"
            width="200px"
            height="50px"
          />
        </a>
      </RegularContainer>

      <RegularContainer
        css={copyRow}
        style={{
          color: colors.neutrals90,
          fontSize: '14px',
          display: 'flex',
        }}
      >
        &copy; {new Date().getFullYear()} Opendoor
        <div
          css={css`
            margin-left: auto;
          `}
        >
          <FairHousing
            alt="Equal Opportunity Housing Logo"
            src="https://imgdrop.imgix.net/2cfd25967a794adabcc97873553da5ab.png"
            loading="lazy"
            width="50px"
            height="50px"
          />
        </div>
      </RegularContainer>

      <RegularContainer style={{ marginTop: '20px' }}>
        <Box mb={8}>
          <LegalP>
            Learn more about the{' '}
            <a href="https://www.opendoor.com/w/terms#brokerages">Opendoor Brokerages</a>
          </LegalP>
        </Box>
        <Flex flexDirection="column" mb={8}>
          <Link
            aria-label={null}
            analyticsName={''}
            fontSize="s00"
            variant="inline-primary"
            href="https://www.opendoor.com/w/contact-us"
          >
            Contact Opendoor Brokerage LLC and Opendoor Brokerage Inc
          </Link>
          <Text fontSize="s00" color="neutrals90" my={3}>
            Opendoor Brokerage LLC and Opendoor Brokerage Inc. hold real estate brokerage{' '}
            <Link
              variant="inline-primary"
              aria-label={null}
              analyticsName={''}
              fontSize="s00"
              href="https://www.opendoor.com/articles/brokerage"
            >
              licenses
            </Link>{' '}
            in multiple states.
          </Text>
          <Box display="inline-flex" gap="2" ml="9">
            <Text fontSize="s00" color="neutrals90" my={3}>
              California: Opendoor Brokerage Inc. California DRE# 02061130
            </Text>
          </Box>
          <Box display="inline-flex" gap="2" ml="9">
            <Text fontSize="s00" color="neutrals90">
              New York:
            </Text>
            <Link
              aria-label={null}
              analyticsName={''}
              variant="inline-primary"
              fontSize="s00"
              href="https://s3.amazonaws.com/odfil.es/static/documents/SOP-NYS-OD.pdf"
            >
              § 442-H New York Standard Operating Procedures
            </Link>
            <br />
            <Link
              aria-label={null}
              analyticsName={''}
              variant="inline-primary"
              fontSize="s00"
              href="https://s3.amazonaws.com/odfil.es/static/documents/fair-housing-notice.pdf"
            >
              § New York Fair Housing Notice
            </Link>
            <br />
          </Box>
          <Box display="inline-flex" gap="2" ml="9">
            <Text fontSize="s00" color="neutrals90">
              Texas: TREC:
            </Text>
            <Link
              href={`https://buy.opendoor.com/legal/texas/trec/iabs.pdf`}
              variant="inline-primary"
              fontSize="s00"
              aria-label={null}
              analyticsName={''}
              rel="nofollow"
            >
              Information about Brokerage Services
            </Link>
            <Link
              href={`https://www.trec.texas.gov/forms/consumer-protection-notice`}
              aria-label={null}
              analyticsName={''}
              fontSize="s00"
              variant="inline-primary"
              rel="nofollow"
            >
              Consumer Protection Notice
            </Link>
          </Box>
        </Flex>
        {props.addLegaleseText && <LegalP>{props.addLegaleseText}</LegalP>}
        {props.addLegaleseContent && props.addLegaleseContent}
        <LegalP>
          Opendoor Labs Inc. is working with{' '}
          <a href="https://accessible360.com/" rel="noopener noreferrer">
            Accessible360
          </a>{' '}
          to enhance the accessibility of our websites and mobile applications. We view
          accessibility as a continually ongoing effort. Please contact us at support@opendoor.com
          with any feedback or suggestions.
        </LegalP>
        {!props.hideLegalese && (
          <>
            <LegalP>
              Offer eligibility and purchase price vary. Featured customers may have been
              compensated for their time. Their opinions and views are solely their own.
            </LegalP>
            <br />
          </>
        )}
      </RegularContainer>
    </FooterSection>
  );
};

export default Footer;
